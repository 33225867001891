import { template as template_e9c6575ec276490ca3fe2ecf95e21f9c } from "@ember/template-compiler";
const WelcomeHeader = template_e9c6575ec276490ca3fe2ecf95e21f9c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
